<template>
  <footer class="footer mt-auto py-3 text-center">
    <div class="container">
      <!-- <span class="text-center">Φυτουκλάκι</span> -->
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        currentYear: new Date().getFullYear(),
        app_version: process.env.VUE_APP_VERSION,
      };
    },
  };
</script>

<style>
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    background-color: #77c778;
    color: #fff;
  }
</style>
